import axios from 'axios'
import { xml2json } from 'xml-js'

const apprvKey = '0oo0900ak8ekx1q2iz83'

// ******************** NITS 공통 함수 시작  ********************
// object -> string 변환  ex){ au01: ''} -> 'au01= 최재익'
const ntisQueryBuilder = (endpoint, opt) => {
  const obj = {
    startPosition: 0,
    displayCnt: 100,
    ...opt
  }

  for (const key in obj) {
    endpoint += `&${key}=${obj[key]}`
  }
  return endpoint
}

// NTIS API URL 엔드포인트 types에 담기
const types = {
  pjt: ['pjtSearch', 'project'],
  paper: ['natRnDSearch', 'rpaper'],
  patent: ['natRnDSearch', 'rpatent']
}

// CN(성과 번호)로 한 건 검색
const execAxios = async (type, key) => {
  const url = `/rndopen/openApi/${types[type][0]}/?apprvKey=${apprvKey}&collection=${types[type][1]}&addQuery=CN=${key}`
  const result = await axios.get(url)

  const parsed = xml2json(result.data, { compact: true })
  return JSON.parse(parsed).RESULT.RESULTSET.HIT
}

// 옵션 값으로 전체 검색
const execAxiosAll = async (type, opt) => {
  const obj = typeof opt === 'string' ? { SRWR: opt } : opt
  const url = `/rndopen/openApi/${types[type][0]}/?apprvKey=${apprvKey}&collection=${types[type][1]}`
  const result = await axios.get(ntisQueryBuilder(url, obj))

  const parsed = xml2json(result.data, { compact: true })

  const { TOTALHITS, RESULTSET } = JSON.parse(parsed).RESULT
  return {
    TOTALHITS: Number(TOTALHITS._text),
    LIST: !RESULTSET.HIT ? [] : RESULTSET.HIT instanceof Array ? RESULTSET.HIT : [RESULTSET.HIT]
  }
}

// ******************** NITS 공통 함수 끝 ********************

/**
 * ProjectNumber: 과제고유번호
 * ProjectTitle: 과제명
 * ProjectYear: 기준년도
 * BudgetProject: 예산사업
 * Manager: 연구책임자
 * ResearchAgency: 과제수행기관
 * Ministry: 부처
 * GovernmentFunds: 정부투자연구비
 * TotalFunds: 총연구비
 * Abstract: 연구내용
 */

// ******************** 프로젝트 ********************
// 프로젝트 전체 검색
export const searchPjtAll = async (opt) => {
  const { TOTALHITS, LIST } = await execAxiosAll('pjt', opt)
  return {
    TOTALHITS,
    LIST: LIST.map(({ ProjectNumber, Manager, ProjectTitle }, idx) => {
      return {
        projectNo: idx + 1, // 게시글 번호
        projectNumber: ProjectNumber._text, // 과제고유번호
        projectManager: Manager.Name._text, // 책임연구자명
        projectTitleKr: ProjectTitle.Korean._text // 과제명
      }
    })
  }
}

// 프로젝트 상세 검색 (검색결과에서 선택된 프로젝트 정보)
export const searchPjt = async (pjtNum) => {
  const {
    ProjectTitle,
    ProjectNumber,
    ProjectYear,
    GovernmentFunds,
    Abstract,
    BudgetProject,
    Manager,
    LeadAgency,
    Ministry,
    TotalFunds,
    Researchers,
    Goal,
    Effect,
    Keyword,
    OrderAgency,
    ResearchAgency,
    BigprojectTitle,
    ManageAgency,
    ProjectPeriod,
    OrganizationPNumber,
    ScienceClass,
    MinistryScienceClass,
    TempScienceClass,
    PerformAgent,
    DevelopmentPhases,
    TechnologyLifecycle,
    Region,
    EconomicSocialGoal,
    SixTechnology,
    ApplyArea,
    ContinuousFlag,
    PolicyProjectFlag,
    ObtainOrganization,
    SbusinessFunds,
    CorporateRegistrationNumber,
    SeriesProject,
    DetailSeriesProject
  } = await execAxios('pjt', pjtNum)

  return {
    projectNumber: ProjectNumber._text,
    projectTitleKr: ProjectTitle.Korean._text,
    projectTitleEn: ProjectTitle.English._text,
    projectManager: Manager.Name._text,
    projectResearchers: Researchers.Name._text,
    projectGoal: Goal.Full._text,
    projectAbstract: Abstract.Full._text,
    projectEffect: Effect.Full._text,
    projectKeywordKr: Keyword.Korean._text,
    projectKeywordEn: Keyword.English._text,
    projectOrderAgency: OrderAgency.Name._text,
    projectOrderAgencyCd: OrderAgency.Code._text,
    projectLeadAgency: LeadAgency._text,
    projectResearchAgency: ResearchAgency.Name._text,
    projectResearchAgencyCd: ResearchAgency.Code._text,
    projectBudgetProject: BudgetProject.Name._text,
    projectBudgetProjectCd: BudgetProject.Code._text,
    projectBigprojectTitle: BigprojectTitle._text,
    projectManageAgency: ManageAgency.Name._text,
    projectManageAgencyCd: ManageAgency.Code._text,
    projectMinistry: Ministry.Name._text,
    projectMinistryCd: Ministry.Code._text,
    projectYear: ProjectYear._text,
    projectProjectPeriodTotalStart: ProjectPeriod.TotalStart._text,
    projectProjectPeriodTotalEnd: ProjectPeriod.TotalEnd._text,
    projectOrganizationPNumber: OrganizationPNumber._text,
    projectScienceClassOldLarge: ScienceClass[0].Large._text,
    projectScienceClassOldMedium: ScienceClass[0].Medium._text,
    projectScienceClassOldSmall: ScienceClass[0].Small._text,
    projectScienceClassNew1Large: ScienceClass[1].Large._text,
    projectScienceClassNew1Medium: ScienceClass[1].Medium._text,
    projectScienceClassNew1Small: ScienceClass[1].Small._text,
    projectScienceClassNew2Large: ScienceClass[2].Large._text,
    projectScienceClassNew2Medium: ScienceClass[2].Medium._text,
    projectScienceClassNew2Small: ScienceClass[2].Small._text,
    projectScienceClassNew3Large: ScienceClass[3].Large._text,
    projectScienceClassNew3Medium: ScienceClass[3].Medium._text,
    projectScienceClassNew3Small: ScienceClass[3].Small._text,
    projectMinistryScienceClassL: MinistryScienceClass.Large._text,
    projectMinistryScienceClassM: MinistryScienceClass.Medium._text,
    projectMinistryScienceClassS: MinistryScienceClass.Small._text,
    projectTempScienceClassL: TempScienceClass.Large._text,
    projectTempScienceClassM: TempScienceClass.Medium._text,
    projectTempScienceClassS: TempScienceClass.Small._text,
    projectPerformAgent: PerformAgent._text,
    projectDevelopmentPhases: DevelopmentPhases._text,
    projectTechnologyLifecycle: TechnologyLifecycle._text,
    projectRegion: Region._text,
    projectEconomicSocialGoal: EconomicSocialGoal._text,
    projectSixTechnology: SixTechnology._text,
    projectApplyArea1: ApplyArea.First._text,
    projectApplyArea2: ApplyArea.Second._text,
    projectApplyArea3: ApplyArea.Third._text,
    projectContinuousFlag: ContinuousFlag._text,
    projectPolicyProjectFlag: PolicyProjectFlag._text,
    projectObtainOrganization: ObtainOrganization._text,
    projectGovernmentFunds: GovernmentFunds._text,
    projectSbusinessFunds: SbusinessFunds._text,
    projectTotalFunds: TotalFunds._text,
    projectCorporateRegistrationNumber: CorporateRegistrationNumber._text,
    projectSeriesProject: SeriesProject._text,
    projectDetailSeriesProject: DetailSeriesProject._text
  }
}
// 프로젝트 검색 옵션
export const searchProjectConditions = [
  { value: 'all', text: '전체' },
  { value: 'AU01', text: '책임연구자명' },
  { value: 'TI01', text: '과제명' },
  { value: 'PB08', text: '과제고유번호' },
  { value: 'PB01', text: '과제수행기관명' }
]

/**
 * 프로젝트 검색 결과 가져오기
 * @param {*} startPosition 시작위치
 * displayCnt: 한 번에 가져올 검색 결과 수
 * @param {*} keyword1 검색어
 * @param {*} select1  검색 필드
 */
const totalPjtData = async (startPosition, keyword1, keyword2, keyword3, select1, select2, select3) => {
  const url = `/rndopen/openApi/pjtSearch/?apprvKey=${apprvKey}&collection=project&startPosition=${startPosition}
							&displayCnt=1000&addQuery=${select1}${keyword1}${select2}${keyword2}${select3}${keyword3}`
  const result = await axios.get(url)

  const parsed = xml2json(result.data, { compact: true })

  const { TOTALHITS, RESULTSET } = JSON.parse(parsed).RESULT

  // console.log(JSON.parse(parsed).RESULT)

  return {
    TOTALHITS: Number(TOTALHITS._text),
    LIST: !RESULTSET.HIT ? [] : RESULTSET.HIT instanceof Array ? RESULTSET.HIT : [RESULTSET.HIT]
  }
}
// 프로젝트 전체 검색
export const getTotalPjtData = async (startPosition, keyword1, keyword2, keyword3, select1, select2, select3) => {
  const { TOTALHITS, LIST } = await totalPjtData(startPosition, keyword1, keyword2, keyword3, select1, select2, select3)
  return {
    TOTALHITS,
    LIST: LIST.map(({ ProjectNumber, Manager, ProjectTitle }, idx) => {
      return {
        projectNo: idx + 1, // 게시글 번호
        projectNumber: ProjectNumber._text, // 과제고유번호
        projectManager: Manager.Name._text, // 책임연구자명
        projectTitleKr: ProjectTitle.Korean._text // 과제명
      }
    })
  }
}

// ******************** 논문 ********************
/**
 * 논문 전체 검색
 * @param {*} opt
 * @returns
 */
export const searchPaperAll = async (opt) => {
  const { TOTALHITS, LIST } = await execAxiosAll('paper', opt)
  return {
    TOTALHITS,
    LIST: LIST.map(
      (
        {
          Abstract,
          Author,
          BudgetProject,
          BudgetProjectNumber,
          IssnNumber,
          JCType,
          JournalName,
          Keyword,
          KeywordFlag,
          MinistryName,
          NationType,
          PerformAgency,
          PerformAgent,
          ProjectID,
          ProjectTitle,
          PubYear,
          ResultID,
          ResultTitle,
          SciType,
          ScienceClass1,
          ScienceClass2,
          ScienceClass3,
          SixTechnology,
          SourceFlag,
          TechnologyRoadMap,
          PaperType,
          ProjectYear
        },
        idx
      ) => {
        return {
          paperNo: idx + 1, // 게시글 번호
          paperAbstract: Abstract.Full._text, // 초록
          paperAuthor: Author._text, // 저자명
          paperBudgetProject: BudgetProject._text, // 사업명
          paperBudgetProjectNumber: BudgetProjectNumber._text, // 사업코드
          paperIssnNumber: IssnNumber._text, // ISSN
          paperJctType: JCType._text, // 학술지 구분
          paperJournalName: JournalName._text, // 학술지명
          paperKeyword: Keyword.Korean._text, // 키워드(한글)
          paperKeywordEn: Keyword.English._text, // 키워드(영어)
          paperKeywordFlag: KeywordFlag._text, // 키워드유무
          paperMinistryName: MinistryName._text, // 부처명
          paperNationType: NationType._text, // 국내외 구분
          paperPerformAgency: PerformAgency._text, // 과제수행기관명
          paperPerformAgent: PerformAgent._text, // 연구수행주체코드
          paperProjectID: ProjectID._text, // 과제고유번호
          paperProjectTitle: ProjectTitle._text, // 과제명
          paperPubYear: PubYear._text, // 성과연도
          paperResultID: ResultID._text, // 성과번호
          paperResultTitle: ResultTitle._text, // 논문명
          paperSciType: SciType._text, // SCI 구분
          paperScienceClass1: ScienceClass1._text, // 과학기술표준분류명1
          paperScienceClass2: ScienceClass2._text, // 과학기술표준분류명2
          paperScienceClass3: ScienceClass3._text, //  과학기술표준분류명3
          paperSixTechnology: SixTechnology._text, // 6T분류명
          paperSourceFlag: SourceFlag._text, // 원문여부
          paperTechnologyRoadMap: TechnologyRoadMap._text, // 국가기술지도명
          paperType: PaperType._text, // 국내외구분
          paperYear: ProjectYear._text // 과제기준연도
        }
      }
    )
  }
}

/**
 * 논문 상세 검색 (검색결과에서 선택된 논문 정보)
 * @param {*} paperNum 성과번호
 */
export const searchPaper = async (paperNum) => {
  const {
    Abstract,
    Author,
    BudgetProject,
    BudgetProjectNumber,
    IssnNumber,
    JCType,
    JournalName,
    Keyword,
    KeywordFlag,
    MinistryName,
    NationType,
    PerformAgency,
    PerformAgent,
    ProjectID,
    ProjectTitle,
    PubYear,
    ResultID,
    ResultTitle,
    SciType,
    ScienceClass1,
    ScienceClass2,
    ScienceClass3,
    SixTechnology,
    SourceFlag,
    TechnologyRoadMap,
    PaperType,
    ProjectYear
  } = await execAxios('paper', paperNum)
  return {
    paperAbstract: Abstract.Full._text, // 초록
    paperAuthor: Author._text, // 저자명
    paperBudgetProject: BudgetProject._text, // 사업명
    paperBudgetProjectNumber: BudgetProjectNumber._text, // 사업코드
    paperIssnNumber: IssnNumber._text, // ISSN
    paperJctType: JCType._text, // 학술지 구분
    paperJournalName: JournalName._text, // 학술지명
    paperKeyword: Keyword.Korean._text, // 키워드(한글)
    paperKeywordEn: Keyword.English._text, // 키워드(영어)
    paperKeywordFlag: KeywordFlag._text, // 키워드유무
    paperMinistryName: MinistryName._text, // 부처명
    paperNationType: NationType._text, // 국내외 구분
    paperPerformAgency: PerformAgency._text, // 과제수행기관명
    paperPerformAgent: PerformAgent._text, // 연구수행주체코드
    paperProjectID: ProjectID._text, // 과제고유번호
    paperProjectTitle: ProjectTitle._text, // 과제명
    paperPubYear: PubYear._text, // 성과연도
    paperResultID: ResultID._text, // 성과번호
    paperResultTitle: ResultTitle._text, // 논문명
    paperSciType: SciType._text, // SCI 구분
    paperScienceClass1: ScienceClass1._text, // 과학기술표준분류명1
    paperScienceClass2: ScienceClass2._text, // 과학기술표준분류명2
    paperScienceClass3: ScienceClass3._text, //  과학기술표준분류명3
    paperSixTechnology: SixTechnology._text, // 6T분류명
    paperSourceFlag: SourceFlag._text, // 원문여부
    paperTechnologyRoadMap: TechnologyRoadMap._text, // 국가기술지도명
    paperType: PaperType._text, // 국내외구분
    paperYear: ProjectYear._text // 과제기준연도
  }
}
// 논문 검색 옵션
export const cccPaperConditions = [
  { value: 'all', text: '전체' },
  { value: 'TI01', text: '논문명' },
  { value: 'TI03', text: '과제고유번호' },
  { value: 'AU01', text: '저자명' },
  { value: 'OG01', text: 'ISSN 번호' },
  { value: 'PB01', text: '학술지명' }
]

/**
 * 논문 검색 결과 가져오기
 * @param {*} keyword1 검색어 : 과제고유번호, 논문명, 저자명, ISSN, 학술지명
 * @param {*} select1 검색필드
 */
const totalPaperData = async (keyword1, select1, keyword2, select2, keyword3, select3, keyword4, select4, keyword5, select5) => {
  const url = `/rndopen/openApi/natRnDSearch/?apprvKey=${apprvKey}&collection=rpaper&startPosition=1&displayCnt=1000
							&addQuery=${select1}${keyword1}${select2}${keyword2}${select3}${keyword3}${select4}${keyword4}${select5}${keyword5}`
  const result = await axios.get(url)

  const parsed = xml2json(result.data, { compact: true })

  const { TOTALHITS, RESULTSET } = JSON.parse(parsed).RESULT
  return {
    TOTALHITS: Number(TOTALHITS._text),
    LIST: !RESULTSET.HIT ? [] : RESULTSET.HIT instanceof Array ? RESULTSET.HIT : [RESULTSET.HIT]
  }
}
// 논문 전체 검색
export const getTotalPaperData = async (keyword1, select1, keyword2, select2, keyword3, select3, keyword4, select4, keyword5, select5) => {
  const { TOTALHITS, LIST } = await totalPaperData(
    keyword1,
    select1,
    keyword2,
    select2,
    keyword3,
    select3,
    keyword4,
    select4,
    keyword5,
    select5
  )
  return {
    TOTALHITS,
    LIST: LIST.map(
      (
        {
          Abstract,
          Author,
          BudgetProject,
          BudgetProjectNumber,
          IssnNumber,
          JCType,
          JournalName,
          Keyword,
          KeywordFlag,
          MinistryName,
          NationType,
          PerformAgency,
          PerformAgent,
          ProjectID,
          ProjectTitle,
          PubYear,
          ResultID,
          ResultTitle,
          SciType,
          ScienceClass1,
          ScienceClass2,
          ScienceClass3,
          SixTechnology,
          SourceFlag,
          TechnologyRoadMap,
          PaperType,
          ProjectYear
        },
        idx
      ) => {
        return {
          paperNo: idx + 1, // 게시글 번호
          paperAbstract: Abstract.Full._text, // 초록
          paperAuthor: Author._text, // 저자명
          paperBudgetProject: BudgetProject._text, // 사업명
          paperBudgetProjectNumber: BudgetProjectNumber._text, // 사업코드
          paperIssnNumber: IssnNumber._text, // ISSN
          paperJctType: JCType._text, // 학술지 구분
          paperJournalName: JournalName._text, // 학술지명
          paperKeyword: Keyword.Korean._text, // 키워드(한글)
          paperKeywordEn: Keyword.English._text, // 키워드(영어)
          paperKeywordFlag: KeywordFlag._text, // 키워드유무
          paperMinistryName: MinistryName._text, // 부처명
          paperNationType: NationType._text, // 국내외 구분
          paperPerformAgency: PerformAgency._text, // 과제수행기관명
          paperPerformAgent: PerformAgent._text, // 연구수행주체코드
          paperProjectID: ProjectID._text, // 과제고유번호
          paperProjectTitle: ProjectTitle._text, // 과제명
          paperPubYear: PubYear._text, // 성과연도
          paperResultID: ResultID._text, // 성과번호
          paperResultTitle: ResultTitle._text, // 논문명
          paperSciType: SciType._text, // SCI 구분
          paperScienceClass1: ScienceClass1._text, // 과학기술표준분류명1
          paperScienceClass2: ScienceClass2._text, // 과학기술표준분류명2
          paperScienceClass3: ScienceClass3._text, //  과학기술표준분류명3
          paperSixTechnology: SixTechnology._text, // 6T분류명
          paperSourceFlag: SourceFlag._text, // 원문여부
          paperTechnologyRoadMap: TechnologyRoadMap._text, // 국가기술지도명
          paperType: PaperType._text, // 국내외구분
          paperYear: ProjectYear._text // 과제기준연도
        }
      }
    )
  }
}

// ******************** 지재권 ********************
/**
 * 지재권 전체 검색
 * @param {*} opt
 * @returns
 */
export const searchPatentAll = async (opt) => {
  const { TOTALHITS, LIST } = await execAxiosAll('patent', opt)
  return {
    TOTALHITS,
    LIST: LIST.map(
      (
        {
          ResultID,
          ResultTitle,
          Registrant,
          RegistCountry,
          Year,
          RegistNumber,
          RegistType,
          ProjectID,
          IprType,
          ProjectYear,
          BudgetProjectNumber,
          BudgetProject,
          MinistryName,
          ProjectTitle,
          PerformAgency,
          PerformAgent,
          SixTechnology,
          TechnologyRoadMap,
          ScienceClass1,
          ScienceClass2,
          ScienceClass3
        },
        idx
      ) => {
        return {
          patentNo: idx + 1, //
          patentResultID: ResultID._text,
          patentResultTitle: ResultTitle._text,
          patentRegistrant: Registrant._text,
          patentRegistCountry: RegistCountry._text,
          patentYear: Year._text,
          patentRegistNumber: RegistNumber._text,
          patentRegistType: RegistType._text,
          patentProjectID: ProjectID._text,
          patentIprType: IprType._text,
          patentProjectYear: ProjectYear._text,
          patentBudgetProjectNumber: BudgetProjectNumber._text,
          patentBudgetProject: BudgetProject._text,
          patentMinistryName: MinistryName._text,
          patentProjectTitle: ProjectTitle._text,
          patentPerformAgency: PerformAgency._text,
          patentPerformAgent: PerformAgent._text,
          patentSixTechnology: SixTechnology._text,
          patentTechnologyRoadMap: TechnologyRoadMap._text,
          patentScienceClass1: ScienceClass1._text,
          patentScienceClass2: ScienceClass2._text,
          patentScienceClass3: ScienceClass3._text
        }
      }
    )
  }
}
/**
 * 지재권 상세 검색(검색결과에서 선택된 프로젝트 정보)
 * @param {*} patentNum 성과번호
 * @returns
 */
export const searchPatent = async (patentNum) => {
  const {
    ResultID,
    ResultTitle,
    Registrant,
    RegistCountry,
    Year,
    RegistNumber,
    RegistType,
    ProjectID,
    IprType,
    ProjectYear,
    BudgetProjectNumber,
    BudgetProject,
    MinistryName,
    ProjectTitle,
    PerformAgency,
    PerformAgent,
    SixTechnology,
    TechnologyRoadMap,
    ScienceClass1,
    ScienceClass2,
    ScienceClass3
  } = await execAxios('patent', patentNum)
  return {
    patentResultID: ResultID._text,
    patentResultTitle: ResultTitle._text,
    patentRegistrant: Registrant._text,
    patentRegistCountry: RegistCountry._text,
    patentYear: Year._text,
    patentRegistNumber: RegistNumber._text,
    patentRegistType: RegistType._text,
    patentProjectID: ProjectID._text,
    patentIprType: IprType._text,
    patentProjectYear: ProjectYear._text,
    patentBudgetProjectNumber: BudgetProjectNumber._text,
    patentBudgetProject: BudgetProject._text,
    patentMinistryName: MinistryName._text,
    patentProjectTitle: ProjectTitle._text,
    patentPerformAgency: PerformAgency._text,
    patentPerformAgent: PerformAgent._text,
    patentSixTechnology: SixTechnology._text,
    patentTechnologyRoadMap: TechnologyRoadMap._text,
    patentScienceClass1: ScienceClass1._text,
    patentScienceClass2: ScienceClass2._text,
    patentScienceClass3: ScienceClass3._text
  }
}
// 지재권 검색 옵션
export const searchPatentConditions = [
  { value: 'all', text: '전체' },
  { value: 'TI01', text: '출원등록명' },
  { value: 'AU01', text: '출원등록인' },
  { value: 'OG01', text: '출원등록번호' },
  { value: 'PB02', text: '출원등록국' },
  { value: 'TI03', text: '과제고유번호' }
]

/**
 * 지재권 검색 결과 가져오기
 * @param {*} keyword1 검색어 : 과제고유번호, 출원등록명, 출원등록인, 출원등록번호, 출원등록국
 * @param {*} select1 검색필드
 * @returns
 */
const totalPatentData = async (keyword1, select1, keyword2, select2, keyword3, select3, keyword4, select4, keyword5, select5) => {
  const url = `/rndopen/openApi/natRnDSearch/?apprvKey=${apprvKey}&collection=rpatent&startPosition=1&displayCnt=1000
							&addQuery=${select1}${keyword1}${select2}${keyword2}${select3}${keyword3}${select4}${keyword4}${select5}${keyword5}`
  const result = await axios.get(url)
  const parsed = xml2json(result.data, { compact: true })

  const { TOTALHITS, RESULTSET } = JSON.parse(parsed).RESULT
  return {
    TOTALHITS: Number(TOTALHITS._text),
    LIST: !RESULTSET.HIT ? [] : RESULTSET.HIT instanceof Array ? RESULTSET.HIT : [RESULTSET.HIT]
  }
}
// 지재권 전체 검색
export const getTotalPatentData = async (keyword1, select1, keyword2, select2, keyword3, select3, keyword4, select4, keyword5, select5) => {
  const { TOTALHITS, LIST } = await totalPatentData(
    keyword1,
    select1,
    keyword2,
    select2,
    keyword3,
    select3,
    keyword4,
    select4,
    keyword5,
    select5
  )
  return {
    TOTALHITS,
    LIST: LIST.map(
      (
        {
          ResultID,
          ResultTitle,
          Registrant,
          RegistCountry,
          Year,
          RegistNumber,
          RegistType,
          ProjectID,
          IprType,
          ProjectYear,
          BudgetProjectNumber,
          BudgetProject,
          MinistryName,
          ProjectTitle,
          PerformAgency,
          PerformAgent,
          SixTechnology,
          TechnologyRoadMap,
          ScienceClass1,
          ScienceClass2,
          ScienceClass3
        },
        idx
      ) => {
        return {
          patentNo: idx + 1, //
          patentResultID: ResultID._text,
          patentResultTitle: ResultTitle._text,
          patentRegistrant: Registrant._text,
          patentRegistCountry: RegistCountry._text,
          patentYear: Year._text,
          patentRegistNumber: RegistNumber._text,
          patentRegistType: RegistType._text,
          patentProjectID: ProjectID._text,
          patentIprType: IprType._text,
          patentProjectYear: ProjectYear._text,
          patentBudgetProjectNumber: BudgetProjectNumber._text,
          patentBudgetProject: BudgetProject._text,
          patentMinistryName: MinistryName._text,
          patentProjectTitle: ProjectTitle._text,
          patentPerformAgency: PerformAgency._text,
          patentPerformAgent: PerformAgent._text,
          patentSixTechnology: SixTechnology._text,
          patentTechnologyRoadMap: TechnologyRoadMap._text,
          patentScienceClass1: ScienceClass1._text,
          patentScienceClass2: ScienceClass2._text,
          patentScienceClass3: ScienceClass3._text
        }
      }
    )
  }
}
